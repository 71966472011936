@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  background-color: white;
  /* background-image: linear-gradient(to right, #000000 , #f6f6f5); */
}
.swal2-styled.swal2-confirm{
  background-color: #eeb310;
}