.housing {
  width: 45px;
}

.footer_para {
  color: #ccc;
}

.footer_paratwo {
  display: flex;
  justify-content: center;
}
.footerContainer {
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .footer_paratwo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width:340px) {
    .footer-extra{
      display: none;
    }
  }
}
