.check {
  border: 2px solid red;
}

.Payment_card {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  border: 2px solid lightblue;
  background-color: white;
  padding: 10px;
}
.backtohomebtn {
  margin-top: 0.5rem;
}
.SuccessImg {
  width: 120px;
}

.linkbtn {
  text-decoration: none;
  color: black;
}
