.check {
  border: 2px solid red;
}

.sideLogoBg {
  height: 95vh;
  width: 100%;
  background: #fff;
  text-align: center;
  padding-top: 5%;
}

.sideLogoBg img{
  width: 60%;
}

.show_hide_block {
  display: block;
}
.formContainer {
  padding: 4rem 6rem 4rem 8rem;
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.s1lLogo {
  width: 20rem;
  display: flex;
  justify-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .show_hide_block {
    display: none;
  }
  .formContainer {
    margin-top: 2rem;
    padding: 3.5rem;
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .show_hide_block {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .formContainer {
    margin-top: 10rem;
  }
}
a.active{
  color: #fab900;
  font-weight: 200;
  font-size: 18px;
}
a.inActive{
  color: #000000;
  font-weight: 200;
  font-size: 14px;
}
.footer{
  /* min-height: 300px; */
  width: 100%;
  background-color: #363636;
  color: #fff;
  margin-top: 1rem!important;
}
.footer-left{
  width: 30%;
}
.footer a{
  color: #fff!important;
}
.footer-left .logo{
  max-width: 300px;
}
.footer-bottom{
  text-align: center;
}

.MuiPaper-elevation1{
  box-shadow: none !important;
}
.paper{
  padding: 30px;
  text-align: center;
  color: #ffffff;
  background-color: "#363636" !important;
}
@media only screen and (min-width: 800px) and (max-width: 1023px) {
  .logo-small{
    display: none;
  }
  .logo {
    display: block;
  }
}
@media (max-width: 767px){
  .logo {
    display: none;
  }
  .logo-small{
    display: block !important;
    width: 70px;
  }
  .rightlendinglogo{
padding-left: 3px;
  }
}
@media only screen and (max-width: 767px){
  .padding-paper{
    padding: 21px 10px 2px 10px !important;
    text-align: center;
    color: #ffffff;
    background-color: "#363636" !important;
  }
  .MuiPaper-root{
    background-color: "#363636" !important;
  }
}
